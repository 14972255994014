<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="标题">
                <el-input v-model="search.title" size="small"/>
            </x-search-item>
            <x-search-item label="申请人">
                <el-input v-model="search.applicant" size="small"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column prop="title" label="主要事项" fixed/>
            <el-table-column show-overflow-tooltip width="220" prop="modular" label="模块"/>
            <el-table-column show-overflow-tooltip width="150" prop="applyDate" label="提交时间"/>
            <el-table-column show-overflow-tooltip width="180" prop="applicant" label="申请人"/>
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToAudit(row)">审核</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <component :is="comp" :audit="audit" @submitted="refresh">
        </component>
    </div>
</template>
<script>
    import * as service from "@/service/system/audit";
    import XTableBase from "@/components/x/XTableBase";
    import Vue from 'vue';

    export default {
        name: "AuditList",
        mixins: [XTableBase],
        data() {
            this.refreshService = service.list
            return {
                search: {
                    title: '',
                    applicant: ''
                },
                comp: "div",
                audit: {
                    visible: false,
                    id: ''
                }
            }
        },
        methods: {
            handleToAudit(row) {
                this.audit.visible = true;
                this.audit.id = row.id;
                let selector = row.view;
                Vue.component(selector, (resolve) => require([`@/view/audit/${selector}`], resolve));
                this.comp = selector;
            }
        }
    }
</script>

<style scoped>
</style>
